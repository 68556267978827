import React,{useEffect,useState} from 'react';
import CustomModal from '../../components/CustomModal'
import CustomModalLink from '../../components/CustomModalLink'
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import table_icon from '../../images/table_icon.png'
import Create from './Create'
import Edit from './Edit'
import { fetchWrapper } from '../../helpers';


function List(){

  const [list,setList] = useState([])
  useEffect(() => {
        loadList()
       
       
        
    }, []);

  const loadList = async () => {
      const url = `${process.env.REACT_APP_API_URL}/application/list`;
      const data  = await fetchWrapper.get(url)
      setList(data.data)
  }

  return(
    <>
      <Box p="40px">
        <HStack justifyContent="space-between" mb="20px">
          <Box>
            <Text fontSize="24px" fontWeight="400" fontFamily="Open Sans, sans-serif" color="#000">Applications</Text>
          </Box>

          <Box>
            <CustomModal
              showModalButtonText="Add New Application"
              modalHeader="Create Application"
              modalBody=<Create loadList={loadList} />
            />
          </Box>
        </HStack>

        <div className="table_section">
          <TableContainer>
            <Table variant='striped'>
           
              <Thead>
                <Tr>
                  <Th>Icon</Th>
                  <Th>Name</Th>
                  <Th>Title</Th>
                  
                  <Th>Default Access</Th>
                  <Th></Th>
                  
                </Tr>
              </Thead>
              <Tbody>
              {list?.map(rs => {

                          return (
                <Tr>
                  <Td><Image src={`${process.env.REACT_APP_API_URL}/images/${rs.image}`} /></Td>
                  <Td>{rs.name}</Td>
                  <Td>{rs.title}</Td>
                  <Td>{rs.default_access == 'Y' ? 'Yes' : 'No'}</Td>
                 

                  <Td>

                    <CustomModalLink
                      showModalButtonText={`Edit`}
                      modalHeader={`Edit Application ${rs.name}`}
                      modalBody=<Edit rs={rs} loadList={loadList} />
                    />
                  </Td>
                  
                </Tr>
                )
                        }
                          )}
              </Tbody>
              
            </Table>
          </TableContainer>
        </div>
      </Box>     

      
</>
    )
}

export default List;