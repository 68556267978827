import React from 'react';
import Header from '../components/Header'
import Navbar from '../components/Navbar'
import {InputText} from '../components/FormElement'
import * as Yup from 'yup';
import {
  Box,
  
} from '@chakra-ui/react';
import List from './link/List'

function LinkPage() {
	
	return(
		<>
			<Header />

			
			<List />
		</>
	);
}

export default LinkPage;