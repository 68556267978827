import { useState,useEffect } from "react";
import { googleLogout,useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Link,
  Text,
  Avatar,
  FormControl,
  FormHelperText,
  InputRightElement,
  Image
} from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import { fetchWrapper,history } from '../../helpers';
import { useNavigate } from "react-router-dom";
import login_logo from '../../images/login_logo.png';
import google_login from '../../images/google_login.png';
const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

const SignIn = () => {

    const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleShowClick = () => setShowPassword(!showPassword);


  const responseMessage = (response) => {
        console.log(response);
    };
    const errorMessage = (error) => {
        console.log(error);
    };


    const [ user, setUser ] = useState([]);
    const [ profile, setProfile ] = useState();
    const [ showError, setShowError ] = useState(false);

    const login = useGoogleLogin({

        onSuccess: (codeResponse) => {
            setUser(codeResponse)
            //console.log(JSON.stringify(codeResponse))
            //localStorage.setItem('user', JSON.stringify(codeResponse));
        },
        onError: (error) => console.log('Login Failed:', error)
    });

    useEffect(
        () => {
            if (user) {
                axios
                    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                            Accept: 'application/json'
                        }
                    })
                    .then((res) => {
                        const email = res.data.email;
                        checkUser(email,res.data.name,res.data.picture)
                        //setProfile(res.data);
                    })
                    .catch((err) => console.log(err));
            }
        },
        [ user ]
    );

    const checkUser = async(email,name,image) => {
         setShowError(false)
         const url = `${process.env.REACT_APP_API_URL}/api/auth/check-user`;
         const returnData = await fetchWrapper.post(url,{email:email,name:name,image:image})
         .then((res) => {
                
                localStorage.setItem('user', JSON.stringify(res.accessToken));
                
                navigate('/dashboard');
                
               

         })
         .catch((err) => setShowError(true))
       
         
         
    }

    // log out function to log the user out of google and set the profile array to null
    const logOut = () => {
        googleLogout();
        setProfile(null);
    };

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="gray.200"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Heading fontSize="36px" fontWeight="400" color="#000">Welcome TO</Heading>
        <Image src={login_logo} />
        <Box minW={{ base: "90%", md: "360px" }}>
        
           {profile ? (
                <div>
                    <img src={profile.picture} alt="user image" />
                    <h3>User Logged in</h3>
                    <p>Name: {profile.name}</p>
                    <p>Email Address: {profile.email}</p>
                    <br />
                    <br />
                    <button onClick={logOut}>Log out</button>
                </div>
            ) : (
                <Box display="flex" alignItems="center" justifyContent="center" bg="#fff" borderRadius="10px" py="15px" px="15px" boxShadow="0px 2px 3px 0px #0000002B" fontSize="20px" fontWeight="500" color="#0000008A" onClick={() => login()}>
                    <Image src={google_login} me="15px" /> 
                    <button>Sign in with Google  </button>
                </Box>
            )}


            {showError ? (
                <div>
                    User Not foun
                </div>
            ) : null}
        </Box>

        <Text fontSize="20px" fontWeight="400" color="#000" mt="100px">Copyright 2024, KistPay Private Limited. </Text>
      </Stack>
      
    </Flex>
  );
};

export default SignIn;
