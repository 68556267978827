import React,{useEffect,useState} from 'react';
import { Button,Box,Text,Switch,HStack,Image } from '@chakra-ui/react'
import * as Yup from 'yup';
import gitlab from '../../images/gitlab.png';
import playbook from '../../images/playbook.png';
import hr from '../../images/hr.png';
import hive from '../../images/hive.png';
import slack from '../../images/slack.png';
import jira from '../../images/jira.png';
import password from '../../images/password.png';
import figma from '../../images/figma.png';
import Header from '../../components/Header'
import {InputText,Form,SelectField} from '../../components/FormElement'
import { useLocation } from 'react-router-dom';
import { fetchWrapper } from '../../helpers';

	

function Detail(){
	
	
	const [list,setList] = useState([])
	const [listLink,setListLink] = useState([])
	const [userRs,setUserRs] = useState({})

	const {state} = useLocation()
	

	useEffect(() => {
	        loadUser();
	       	loadList()
        	loadListLink()
	       
	        
	    }, []);

 const loadUser = async () => {
      const url = `${process.env.REACT_APP_API_URL}/user/by-id?id=${state.rs.id}`;
      const data  = await fetchWrapper.get(url)
      setUserRs(data.data)
  }

  const loadList = async () => {
      const url = `${process.env.REACT_APP_API_URL}/application/list`;
      const data  = await fetchWrapper.get(url)
      setList(data.data)
  }

  const loadListLink = async () => {
      const url = `${process.env.REACT_APP_API_URL}/link/list`;
      const data  = await fetchWrapper.get(url)
      setListLink(data.data)
  }

  const updateLink = async (event,link_id,user_id) => {
  	  const url = `${process.env.REACT_APP_API_URL}/user/user-link`;
  	   const data = {active_status:event.target.checked,link_id:link_id,user_id:user_id}
  	   await fetchWrapper.post(url,data)
  	   loadUser();
  }

   const updateApp = async (event,application_id,user_id) => {
   	  
  	  const url = `${process.env.REACT_APP_API_URL}/user/user-app`;
  	   const data = {active_status:event.target.checked,application_id:application_id,user_id:user_id}
  	   await fetchWrapper.post(url,data)
  	   loadUser();

  }

  const updateType = async (event,user_id) => {
   	  
  	  const url = `${process.env.REACT_APP_API_URL}/user/user-type`;
  	   const data = {active_status:event.target.checked,user_id:user_id}
  	   await fetchWrapper.post(url,data)
  	   loadUser();

  }
  

	return (

			<>
				<Header />
				
				<Box p="40px">
					
					<Text fontSize="24px" fontWeight="400" fontFamily="Open Sans, sans-serif" color="#000">{state.rs.first_name} {state.rs.last_name}</Text>
					<Text mb="4px" fontSize="14px" fontWeight="400" fontFamily="Open Sans, sans-serif" color="#000">{state.rs.username}</Text>
					<Text>Admin</Text>
					<Switch colorScheme='teal' lable="Admin"

					onChange={(e) => updateType(e,userRs.id)}	
					 isChecked={userRs.user_type_id == 1 ? true : false} 

					size='lg' mb="30px" />

					<HStack>
						<Box w="580px" bg="#fff" borderColor="#ccc" borderWidth="1px" me="15px">
							<Text borderColor="#ccc" borderBottomWidth="1px" fontSize="20px" pl="20px" py="10px" fontFamily="Open Sans, sans-serif" fontWeight="600" color="#000">Applications</Text>
							
									<Box px="20px" mb="50px">


									{list?.map(rs => {


										const isCheckedValue = !!userRs?.UserScopes?.find(appRs => appRs.application_id === rs.id)	
								
		                      return (
		                      	<>
										<HStack borderBottomWidth="1px" borderColor="#ccc" py="20px" justifyContent="space-between">
											<Box>
											
												<HStack>
													<Box w="68px" h="68px" display="flex" alignItems="center" justifyContent="center" me="20px">
		            									<Image src={`${process.env.REACT_APP_API_URL}/images/${rs.image}`} w="100%" />
		            								</Box>

		            								<Box w="50%">
		            									<Text fontSize="16px" fontWeight="700" fontFamily="Open Sans, sans-serif" color="#000">{rs.name}</Text>
		            									<Text fontSize="14px" fontWeight="400" fontFamily="Open Sans, sans-serif" color="#000">{rs.description}</Text>
		            								</Box>
												</HStack>
											</Box>

											<Box>
												<Switch isChecked={isCheckedValue} id={`app_${rs.id}`} onChange={(ev) => updateApp(ev,rs.id,state.rs.id)}  colorScheme='teal' size='lg' />
											</Box>
										</HStack>

										</>
			            )
			                    }
                      		)}
									</Box>
							
						</Box>

						<Box w="580px" bg="#fff" borderColor="#ccc" borderWidth="1px">
							<Text borderColor="#ccc" borderBottomWidth="1px" fontSize="20px" pl="20px" py="10px" fontFamily="Open Sans, sans-serif" fontWeight="600" color="#000">Links</Text>

							<Box px="20px" mb="50px">

							{listLink?.map(rs => {
								const isCheckedValueLink =  !!userRs?.UserLinks?.find(appRs => appRs.link_id === rs.id)
		                      return (
		                      	<>
								<HStack borderBottomWidth="1px" borderColor="#ccc" py="20px" justifyContent="space-between">
									<Box>
										<HStack>
											<Box w="68px" h="68px" display="flex" alignItems="center" justifyContent="center" me="20px">
            									<Image src={`${process.env.REACT_APP_API_URL}/images/${rs.image}`} w="100%" />
            								</Box>

            								<Box w="60%">
            									<Text fontSize="16px" fontWeight="700" fontFamily="Open Sans, sans-serif" color="#000">{rs.name}</Text>
			            						<Text fontSize="14px" fontWeight="400" fontFamily="Open Sans, sans-serif" color="#000">{rs.description}</Text>
            								</Box>
										</HStack>
									</Box>

									<Box>
										<Switch isChecked={isCheckedValueLink} onChange={(ev) => updateLink(ev,rs.id,state.rs.id)} colorScheme='teal' size='lg' />
									</Box>
								</HStack>

								
								</>
			            )
			                    }
                      		)}
							

								
							</Box>
						</Box>
					</HStack>
				</Box>
			</>

	)
}

export default Detail;