import React,{useEffect,useState} from 'react';
import { Button } from '@chakra-ui/react'
import * as Yup from 'yup';
import {InputText,Form,SelectField} from '../../components/FormElement'

import { fetchWrapper } from '../../helpers';




function Edit({loadList,rs}){
	const [appList,setAppList] = useState([])

	useEffect(() => {
	        
	       	loadApp()
	       
	        
	    }, []);

	const loadApp = async () => {
      const url = `${process.env.REACT_APP_API_URL}/application/list`;
      const data  = await fetchWrapper.get(url)
      setAppList(data.data)
   }

	const [formData, setFormData] = useState({
    first_name: rs.first_name,
    last_name: rs.last_name,
    email: rs.username,
    user_type_id:1,
    id:rs.id
    
  });

  const FormSchema = Yup.object().shape({
	   first_name: Yup.string()
	          .required('Required'),
	    last_name: Yup.string()
	          .required('Required'),
	    email: Yup.string()
	          .required('Required'),
	   
	   
	     
	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		  
	       
	     const url = `${process.env.REACT_APP_API_URL}/user/update`;
  		 await fetchWrapper.post(url,data)
  		 loadList()
	}

	


	return (

			<>
			<Form
			            enableReinitialize
			            validationSchema={FormSchema}
			            initialValues={formData}
			            onSubmit={onSubmit}
			 >


			 	<InputText name="first_name" title="First Name"  />
			 	<InputText name="last_name" title="Last Name"  />
			 	<InputText name="email" title="Email"  />
			 	
			 	
			 	

			 	

			 	
			 	

		         




		         <Button
				            mt={4}
				            colorScheme='teal'
				            
				            type='submit'
		          		>
		           		 Save
		          		</Button>

			</Form>

			</>

	)
}

export default Edit;