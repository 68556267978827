import React,{useEffect,useState} from 'react';
import CustomModal from '../../components/CustomModal'
import CustomModalLink from '../../components/CustomModalLink'
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'

import { Link } from 'react-router-dom';
import Create from './Create'
import Edit from './Edit'

import { fetchWrapper } from '../../helpers';


function List(){

  const [list,setList] = useState([])
  useEffect(() => {
        loadList()
       
       
        
    }, []);

  const loadList = async () => {
      const url = `${process.env.REACT_APP_API_URL}/user/list`;
      const data  = await fetchWrapper.get(url)
      setList(data.data)
  }

  return(
    <>
      <Box p="40px">
        <HStack justifyContent="space-between" mb="20px">
          <Box>
            <Text fontSize="24px" fontWeight="400" fontFamily="Open Sans, sans-serif" color="#000">Users</Text>
          </Box>

          <Box>
            {/*<CustomModal
              showModalButtonText="Add New User"
              modalHeader="Create User"
              modalBody=<Create loadList={loadList} />
            />
            */}
          </Box>
        </HStack>

        <div class="table_section">
          <TableContainer>
            <Table variant='striped'>  
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Email</Th>
                  <Th>Authorized App</Th>
                  <Th>User Type</Th>
                  <Th>Status</Th>
                  
                </Tr>
              </Thead>
              <Tbody>
              {list?.map(rs => {

                          return (
                <Tr>
                  <Td><Link to={`/detail/${rs.id}`} state={{rs:rs}}>{rs.first_name} {rs.last_name}</Link></Td>
                  <Td>{rs.username}</Td>
                  <Td>
                  {
                      rs.UserScopes.map(item => {

                           return (<Badge fontSize="14px" mr="1" fontFamily="Open Sans, sans-serif" fontWeight="400" color="#000" borderWidth="1px" borderColor="#363B78" bg="#EDF5FF" px="15px" py="5px" borderRadius="20px">{item.Application.name}</Badge>)

                      })

                  }
                  </Td>
                  <Td>{rs.UserType?.name}</Td>
                  <Td>
                    <CustomModalLink
                      showModalButtonText={`Edit`}
                      modalHeader={`Edit Application ${rs.name}`}
                      modalBody=<Edit rs={rs} loadList={loadList} />
                    />
                  </Td>
                  
                </Tr>
                )
                        }
                          )}
              </Tbody>
              
            </Table>
          </TableContainer>
        </div>
      </Box>
      

      
</>
    )
}

export default List;