import { ReactNode } from 'react';
import {
  Box,
  Flex,
  Image,
  Text,
  Avatar,
  HStack,
  Link,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
} from '@chakra-ui/react';
import jwt from 'jwt-decode'
import { useNavigate } from "react-router-dom";
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { history, fetchWrapper } from '../helpers';
import logo from '../images/logo.png';




export default function Header() {
  const { isOpen, onOpen, onClose } = useDisclosure();
const navigate = useNavigate();



const token = localStorage.getItem('user') ;


const user = jwt(token);

const usertype_id = user.user_type_id;
let Links = [];
if(usertype_id == 1){
 Links = ['Dashboard', 'User','Application','Link'];
}else{
  
}

const NavLink = ({ children }: { children: ReactNode }) => (
  <Link
    py="22px"
    px="15px"
    color="#fff"
    fontSize="14px"
    fontFamily="Open Sans, sans-serif"
    borderBottom="4px"
    borderRadius="0px"
    borderColor="#3465EA"
    _hover={{
      fontWeight: 700,
      borderBottom: '4px',
      borderRadius: '0px',
      borderColor: "#fff",
    }}
    _active={{
      fontWeight: 700,
      
    }}
    href={`/${children}`}>
    {children}
  </Link>
);


  const logout = () => {
      
        localStorage.removeItem('user');
        navigate('/login');
  }

   
  return (
    <>
   
      <Box px="40px" bg="#3465EA">
        <Flex h="70px" alignItems={'center'} justifyContent={'space-between'}>
          <IconButton
            size={'md'}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={'Open Menu'}
            display={{ md: 'none' }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} alignItems={'center'}>
            <Box>
            <a href='/'>
              <Image src={logo} />
              </a>
            </Box>
            
          </HStack>

          <HStack
            as={'nav'}
            display={{ base: 'none', md: 'flex' }}>
            {Links.map((link) => (
              <NavLink key={link}>{link}</NavLink>
            ))}
          </HStack>
          <Flex alignItems={'center'}>
            <Menu>
              <MenuButton
                as={Button}
                rounded={'full'}
                variant={'link'}
                cursor={'pointer'}
                minW={0}>
                <HStack>
                  <Box>
                    <Avatar
                      size={'sm'}
                      src={
                        user.image
                      }
                    />
                  </Box>

                  <Box>
                    <Text fontFamily="Open Sans, sans-serif" fontSize="12px" fontWeight="400" color="#fff" textAlign="left">Welcome</Text>
                    <Text fontFamily="Open Sans, sans-serif" fontSize="12px" fontWeight="400" color="#fff" textAlign="left">{user?.full_name}</Text>
                  </Box>
                </HStack>
                
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => logout()}>Logout</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: 'none' }}>
            <Stack as={'nav'} spacing={4}>
              {Links.map((link) => (
                <NavLink key={link}>{link}</NavLink>
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>

    </>
  );
}