import React,{useEffect,useState} from 'react';
import Header from '../components/Header'
import Navbar from '../components/Navbar'
import jwt from 'jwt-decode'
import {
  Box,
  Text,
  HStack,
  Image,
  
} from '@chakra-ui/react';
import gitlab from '../images/gitlab.png';
import playbook from '../images/playbook.png';
import hr from '../images/hr.png';
import hive from '../images/hive.png';
import slack from '../images/slack.png';
import jira from '../images/jira.png';
import password from '../images/password.png';
import figma from '../images/figma.png';
import { fetchWrapper } from '../helpers';
import { useNavigate } from "react-router-dom";
function Dashboard() {

	const navigate = useNavigate();
	const [userRs,setUserRs] = useState({})
	const token = localStorage.getItem('user');
   const user = jwt(token);

	const [list,setList] = useState([])
	const [listLink,setListLink] = useState([])
		 useEffect(() => {
		 		loadUser();
        loadList()
        loadListLink()
       
        
    }, []);

	const loadUser = async () => {
      const url = `${process.env.REACT_APP_API_URL}/user/by-id-token?auth_code=${user.auth_code}`;
      const data  = await fetchWrapper.get(url).then(data => {

      		setUserRs(data.data)
      }).catch(error => {

      	 localStorage.removeItem('user');
        	navigate('/login');
      })
      
      	
      	
      
      
  }

	const loadList = async () => {
      const url = `${process.env.REACT_APP_API_URL}/application/list`;
      const data  = await fetchWrapper.get(url)
      setList(data.data)
  }

  const loadListLink = async () => {
      const url = `${process.env.REACT_APP_API_URL}/link/list`;
      const data  = await fetchWrapper.get(url)
     
      	setListLink(data.data)
      
      
  }

 


  const  auth_code = user?.auth_code;

  const redirectUrl = 'http://ssobackend.eocean.net'
	
	return(

		<>
			<Header />
			
			<Box p="40px" bg="#EDF5FF">
			            					

			            					<Box>
			            						<Text fontSize="18px" color="#000" fontWeight="400" fontFamily="Open Sans, sans-serif" mb="15px">Sign in to Applications</Text>
			            					</Box>
<HStack flexWrap="wrap" mb="50px">
			{list?.map(rs => {
						const loginUrl = rs.login_url + '?authorization_code=' + auth_code ;
						const isCheckedValue = !!userRs?.UserScopes?.find(appRs => appRs.application_id === rs.id)
						if(isCheckedValue){
                      return (
                      	<>
			            				

			            					<a href={`${loginUrl}`} target="_blank">
			            						<Box bg="#fff" p="20px" borderWidth="1px" borderColor="#CCCCCC" borderRadius="5px" w="380px" me="12px" mb="15px">
			            							<HStack>
			            								<Box w="68px" h="68px" display="flex" alignItems="center" justifyContent="center" me="20px">
			            									<Image src={`${process.env.REACT_APP_API_URL}/images/${rs.image}`} w="100%" />
			            								</Box>

			            								<Box>
			            									<Text fontSize="16px" fontWeight="700" fontFamily="Open Sans, sans-serif" color="#000">{rs.title}</Text>
			            									<Text fontSize="14px" fontWeight="400" fontFamily="Open Sans, sans-serif" color="#000">{rs.description}</Text>
			            								</Box>
			            							</HStack>
			            						</Box>
			            						</a>

			            					

			            					

			            					
			            					

			            					
			            				
            						</>
            )
                    }
                    }
                      )}

</HStack>
                      <Box>
			            						<Text fontSize="18px" color="#000" fontWeight="400" fontFamily="Open Sans, sans-serif" mb="15px">Useful Links</Text>
			            					</Box>

			            					<HStack flexWrap="wrap" mb="50px">

{listLink?.map(rs => {

								const isCheckedValueLink =  !!userRs?.UserLinks?.find(appRs => appRs.link_id === rs.id)
								if(isCheckedValueLink){
						
                      return (
                      	<>
			            					<a href={`${rs.link}`} target="_blank">
			            						<Box bg="#fff" p="20px" borderWidth="1px" borderColor="#CCCCCC" borderRadius="5px" w="380px" me="12px" mb="15px">
			            							<HStack>
			            								<Box w="68px" h="68px" display="flex" alignItems="center" justifyContent="center" me="20px">
			            									<Image src={`${process.env.REACT_APP_API_URL}/images/${rs.image}`} w="100%" />
			            								</Box>

			            								<Box>
			            									<Text fontSize="16px" fontWeight="700" fontFamily="Open Sans, sans-serif" color="#000">{rs.name}</Text>
			            									<Text fontSize="14px" fontWeight="400" fontFamily="Open Sans, sans-serif" color="#000">{rs.description}</Text>
			            								</Box>
			            							</HStack>
			            						</Box>
			            						</a>
			            						</>
            )
          }
                    }
                      )}

			            						

			            					
			            					</HStack>

			            	</Box>
			
			
		</>
	);
}

export default Dashboard;