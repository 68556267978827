import React from 'react';
import Header from '../components/Header'
import Navbar from '../components/Navbar'
import {
  Box,
  
} from '@chakra-ui/react';
import Create from './invite/Create'
function Invite() {
	
	return(
		<>
			<Header />

			<Box p={4}>

			Invite
			<br />
			
			</Box>


			<Box p={6} w='25%' >

			
			<Create />
			</Box>
			
		</>
	);
}

export default Invite;