import {combineReducers} from "@reduxjs/toolkit";
import { configureStore } from '@reduxjs/toolkit';


import { authReducer } from './auth.slice';
import { usersReducer } from './users.slice';


export * from './auth.slice';


export const store = configureStore({
    reducer: {
        auth: authReducer,
        users: usersReducer,
       
       
    },
});
