import React,{useEffect,useState} from 'react';
import { Button } from '@chakra-ui/react'
import * as Yup from 'yup';
import {InputText,Form,TextAreaField,SelectField} from '../../components/FormElement'

import { fetchWrapper } from '../../helpers';




function Edit({loadList,rs}){

	const [formData, setFormData] = useState({
    name: rs.name,
    
    
    
    description:rs.description,
    link:rs.link,
    image:rs.image,
    id:rs.id,
    default_access:rs.default_access,
    
  });

  const FormSchema = Yup.object().shape({
	   name: Yup.string().required('Required'),
	   
	   description: Yup.string().required('Required'),
	   link: Yup.string().required('Required'),
	   image: Yup.string().required('Required')
	     
	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		  
	       
	     const url = `${process.env.REACT_APP_API_URL}/link/update`;
  		 await fetchWrapper.post(url,data)
  		 loadList()
	}

	const defaultAccessList = [{id:'Y',name:'Yes'},{id:'N',name:'No'}]


	return (

			<>
			<Form
			            enableReinitialize
			            validationSchema={FormSchema}
			            initialValues={formData}
			            onSubmit={onSubmit}
			 >


			 	<InputText name="name" title="Name"  />
			 	<InputText name="link" title="Link"  />
			 	<InputText name="image" title="Image"  />

			 	<TextAreaField name="description" title="Description"  />

			 	

			
			 

			 	<SelectField name="default_access" title="Default Access" list={defaultAccessList} />
			 	

		         




		         <Button
				            mt={4}
				            colorScheme='teal'
				            
				            type='submit'
		          		>
		           		 Save
		          		</Button>

			</Form>

			</>

	)
}

export default Edit;