import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import logo from './logo.svg';
import Login  from './pages/auth/SignIn';
import Dashboard  from './pages/Dashboard';
import Invite  from './pages/Invite';
import Application  from './pages/Application';
import User  from './pages/User';
import LinkPage  from './pages/LinkPage';
import Detail from './pages/user/Detail'
import { PrivateRoute } from './components';

import './App.css';
import { ChakraProvider } from '@chakra-ui/react'
function App() {
  return (
    <ChakraProvider>

    <Routes>
         
          <Route path="/" element={

                            <PrivateRoute>
                                <Dashboard />
                                
                            </PrivateRoute>
          } />
         
          <Route path="/application" element={
            <PrivateRoute>
            <Application />
            </PrivateRoute>
          } />
          <Route path="/user" element={
            <PrivateRoute>
            <User />
            </PrivateRoute>
          } />
          <Route path="/link" element={
            <PrivateRoute>
            <LinkPage />
            </PrivateRoute>
          } />
          <Route path="/detail/:id" element={
            <PrivateRoute>
            <Detail />
            </PrivateRoute>
          } />

         <Route path="/login" element={<Login />} />
         <Route path="*" element={<Navigate to="/" />} />
    </Routes>
    </ChakraProvider>
  );
}

export default App;
