import React,{useEffect,useState} from 'react';
import { Button } from '@chakra-ui/react'
import * as Yup from 'yup';
import {InputText,Form,SelectField,TextAreaField} from '../../components/FormElement'

import { fetchWrapper } from '../../helpers';




function Create({loadList}){

	const [formData, setFormData] = useState({
    name: '',
   
    description: '',
    image:'',
    link:'',
   default_access:'N',
   
    
  });

  const FormSchema = Yup.object().shape({
	   name: Yup.string().required('Required'),
	  
	   link: Yup.string().required('Required'),
	   
	   description: Yup.string().required('Required')

	});

  const defaultAccessList = [{id:'Y',name:'Yes'},{id:'N',name:'No'}]
	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		  
	       
	     const url = `${process.env.REACT_APP_API_URL}/link/create`;
  		 await fetchWrapper.post(url,data)
  		 loadList()
	}

	


	return (

			<>
			<Form
			            enableReinitialize
			            validationSchema={FormSchema}
			            initialValues={formData}
			            onSubmit={onSubmit}
			 >


			 	<InputText name="name" title="Name"  />
			 	
			 	<InputText name="link" title="Link"  />
			 	<TextAreaField name="description" title="Description"  />
			 	

			 	<SelectField name="default_access" title="Default Access" list={defaultAccessList} />
			 	

			 	
			 	

		         




		         <Button
				            mt={4}
				            colorScheme='teal'
				            
				            type='submit'
		          		>
		           		 Save
		          		</Button>

			</Form>

			</>

	)
}

export default Create;