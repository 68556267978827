import React from 'react';
import {
  
  SelectControl,
  
} from "formik-chakra-ui";

function SelectField(props) {
	const {name ,list,title} = props
	return(
		<>
			<div className="field_section">
				

				<SelectControl
		            name={name}
		            label={title}
		            
		            selectProps={{ placeholder: "Select option" }}
		          >
		          {list?.map((item) => {
		          			return (
		          						<option value={item.id}>{item.name}</option>
		          				)

		          })}
		           
		          </SelectControl>
			</div>
		</>
	);
}

export default SelectField;