import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
} from '@chakra-ui/react'
const CustomModalLink = ({ showModalButtonText, modalHeader, modalBody }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();


  return (
    <>
      <a bg="#3465EA" padding="20px" fontSize="14px" fontWeight="400" fontFamily="Open Sans, sans-serif" color="#fff" onClick={onOpen}>
        {showModalButtonText}
      </a>

      <Modal isOpen={isOpen} size='xl' onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalHeader}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {modalBody}

            
          </ModalBody>

          
        </ModalContent>
      </Modal>
    </>
  );
};

export default CustomModalLink;