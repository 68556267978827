import React,{useEffect,useState} from 'react';
import CustomModal from '../../components/CustomModal'
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import Create from './Create'
import Edit from './Edit'
import { fetchWrapper } from '../../helpers';


function List(){

  const [list,setList] = useState([])
  useEffect(() => {
        loadList()
       
       
        
    }, []);

  const loadList = async () => {
      const url = `${process.env.REACT_APP_API_URL}/link/list`;
      const data  = await fetchWrapper.get(url)
      setList(data.data)
  }

  return(
    <>

    <Box p="40px">
        <HStack justifyContent="space-between" mb="20px">
          <Box>
            <Text fontSize="24px" fontWeight="400" fontFamily="Open Sans, sans-serif" color="#000">Links</Text>
          </Box>

          <Box>
             <CustomModal
              showModalButtonText="Create"
              modalHeader="Create Link"
              modalBody=<Create loadList={loadList} />
            />
          </Box>
        </HStack>
     
      <div class="table_section">
      <TableContainer>
        <Table variant='simple'>
       
          <Thead>
            <Tr>
              <Th>Name</Th>
             
              <Th>Description</Th>
              <Th>Link</Th>
              <Th>Default Access</Th>
              <Th></Th>
              
            </Tr>
          </Thead>
          <Tbody>
          {list?.map(rs => {

                      return (
            <Tr>
              <Td>{rs.name}</Td>
              <Td>{rs.description}</Td>
              <Td>{rs.link}</Td>
              <Td>{rs.default_access == 'Y' ? 'Yes' : 'No'}</Td>

              <Td>

                <CustomModal
                                showModalButtonText={`Edit`}
                                modalHeader={`Edit Application ${rs.name}`}
                                modalBody=<Edit rs={rs} loadList={loadList} />
                              />
              </Td>
              
            </Tr>
            )
                    }
                      )}
          </Tbody>
          
        </Table>
      </TableContainer>
      </div>
      </Box>
</>
    )
}

export default List;